import { default as indexTZPBGYm54uMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/about-company/index.vue?macro=true";
import { default as _91slug_93XHOoenFeD4Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/actions/[slug].vue?macro=true";
import { default as indexwul0P6LG2sMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/actions/index.vue?macro=true";
import { default as _91slug_938pkQpiSFcLMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/blog/[slug].vue?macro=true";
import { default as indexJ9Jc959j3RMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/blog/index.vue?macro=true";
import { default as _91slug_933jh7iiCf6uMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/bluda/[slug].vue?macro=true";
import { default as indexJxHwBiRfLYMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/bluda/index.vue?macro=true";
import { default as status3FL4mHF6HbMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/cabinet/payment/status.vue?macro=true";
import { default as indexlI8d7rt5SNMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/cart/index.vue?macro=true";
import { default as successJilBwwJIhnMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/cart/success.vue?macro=true";
import { default as _91slug_93cfr59sHsHRMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/categories/[slug].vue?macro=true";
import { default as index1N1gvdK48tMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/categories/index.vue?macro=true";
import { default as checking_45partnersav6Z1BqWMDMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/checking-partners.vue?macro=true";
import { default as contacts2vmvaSykDIMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/contacts.vue?macro=true";
import { default as indexS7JyzqAXa6Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/delete-account/index.vue?macro=true";
import { default as indexz5qcy5Afm9Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/index.vue?macro=true";
import { default as index4qSXcPOwOpMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/info-for-partners/index.vue?macro=true";
import { default as _91slug_937KimdOpyHFMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/kuhni/[slug].vue?macro=true";
import { default as indexP3IAssbSGXMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/kuhni/index.vue?macro=true";
import { default as offeriCIBwmyC1bMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/offer.vue?macro=true";
import { default as indexjhpDvH98j2Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/partner-apply-from-mobile/index.vue?macro=true";
import { default as privacyuDL3oWWKcqMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/privacy.vue?macro=true";
import { default as addresesA1DBKBZb7kMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/profile/addreses.vue?macro=true";
import { default as indexMXEndyRZP9Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/profile/index.vue?macro=true";
import { default as infoeJv6nce1dDMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/profile/info.vue?macro=true";
import { default as reviewsxYRuuuhwY0Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/profile/reviews.vue?macro=true";
import { default as _91slug_93oPwPJPswguMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/restaurants/[slug].vue?macro=true";
import { default as indexbYcAfYz3WbMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/restaurants/index.vue?macro=true";
import { default as searchxelHzeR9XHMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/search.vue?macro=true";
import { default as standarts_45partnerskoaQ5WY5mNMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/standarts-partners.vue?macro=true";
import { default as terms_45of_45usefyGvhUY8NHMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/terms-of-use.vue?macro=true";
import { default as index1mAMqofkL8Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/wish/index.vue?macro=true";
export default [
  {
    name: "about-company",
    path: "/about-company",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/about-company/index.vue").then(m => m.default || m)
  },
  {
    name: "actions-slug",
    path: "/actions/:slug()",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/actions/[slug].vue").then(m => m.default || m)
  },
  {
    name: "actions",
    path: "/actions",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/actions/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "bluda-slug",
    path: "/bluda/:slug()",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/bluda/[slug].vue").then(m => m.default || m)
  },
  {
    name: "bluda",
    path: "/bluda",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/bluda/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-payment-status",
    path: "/cabinet/payment/status",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/cabinet/payment/status.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    meta: indexlI8d7rt5SNMeta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-success",
    path: "/cart/success",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/cart/success.vue").then(m => m.default || m)
  },
  {
    name: "categories-slug",
    path: "/categories/:slug()",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/categories/[slug].vue").then(m => m.default || m)
  },
  {
    name: "categories",
    path: "/categories",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "checking-partners",
    path: "/checking-partners",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/checking-partners.vue").then(m => m.default || m)
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "delete-account",
    path: "/delete-account",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/delete-account/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info-for-partners",
    path: "/info-for-partners",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/info-for-partners/index.vue").then(m => m.default || m)
  },
  {
    name: "kuhni-slug",
    path: "/kuhni/:slug()",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/kuhni/[slug].vue").then(m => m.default || m)
  },
  {
    name: "kuhni",
    path: "/kuhni",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/kuhni/index.vue").then(m => m.default || m)
  },
  {
    name: "offer",
    path: "/offer",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/offer.vue").then(m => m.default || m)
  },
  {
    name: "partner-apply-from-mobile",
    path: "/partner-apply-from-mobile",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/partner-apply-from-mobile/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "profile-addreses",
    path: "/profile/addreses",
    meta: addresesA1DBKBZb7kMeta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/profile/addreses.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexMXEndyRZP9Meta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-info",
    path: "/profile/info",
    meta: infoeJv6nce1dDMeta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/profile/info.vue").then(m => m.default || m)
  },
  {
    name: "profile-reviews",
    path: "/profile/reviews",
    meta: reviewsxYRuuuhwY0Meta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/profile/reviews.vue").then(m => m.default || m)
  },
  {
    name: "restaurants-slug",
    path: "/restaurants/:slug()",
    meta: _91slug_93oPwPJPswguMeta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/restaurants/[slug].vue").then(m => m.default || m)
  },
  {
    name: "restaurants",
    path: "/restaurants",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/restaurants/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "standarts-partners",
    path: "/standarts-partners",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/standarts-partners.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "wish",
    path: "/wish",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20241114044231/pages/wish/index.vue").then(m => m.default || m)
  }
]